export default class Index {
    form = null;
    successElement = null;
    errorElement = null;

    constructor(rootNode, props) {
        this.form = rootNode.querySelector("form");
        this.successElement = rootNode.querySelector(".flash-success");
        this.errorElement = rootNode.querySelector(".flash-error");

        this.init();
    }

    init() {
        this.form.addEventListener("submit", this.onSubmit.bind(this));
    }

    async onSubmit(e) {
        e.preventDefault();

        this.toggleLoading(true);

        const endpoint = this.form.getAttribute("action");

        const body = new FormData();

        this.appendFormData(body);
        this.appendFiles(body);

        const response = await fetch(endpoint, {
            method: "POST",
            body: body,
        });

        const json = await response.json();

        this.displayFlash(json.success, json.message);

        this.toggleLoading(false);
    }

    toggleLoading(isLoading){
        this.form.classList.toggle('is-loading', isLoading);

        if(isLoading){
            this.form.querySelector('button').setAttribute('disabled', 'disabled');
        }else{
            this.form.querySelector('button').removeAttribute('disabled');
        }
    }

    appendFormData(body) {
        body.append("data", JSON.stringify(this.getPayload()));
    }

    appendFiles(body) {
        const folders = {};

        const fileInputs = this.form.querySelectorAll('input[type="file"]');
        if (fileInputs.length > 0) {
            fileInputs.forEach((input) => {
                folders[input.name] = input.getAttribute('uploadfolder');
                const files = Array.from(input.files);
                if (files.length > 0) {
                    files.forEach((file) => {
                        body.append(input.name, file);
                    });
                }
            });
        }

        body.append("fileFolders", JSON.stringify(folders));
    }

    displayFlash(success, message) {
        this.successElement.style.display = success ? "block" : "none";
        this.errorElement.style.display = !success ? "block" : "none";

        this.successElement.innerText = success ? message : "";
        this.errorElement.innerText = !success ? message : "";

        (success ? this.successElement : this.errorElement).scrollIntoView();

        if(success){
            this.resetForm();
        }
    }

    resetForm(){
        this.form.reset();
    }

    getPayload() {
        const payload = {};

        const formData = new FormData(this.form);

        formData.forEach(function (value, key) {
            payload[key] = value;
        });

        // Make sure unchecked checkboxes are included in the payload
        const checkboxes = this.form.querySelectorAll("input[type=checkbox]");
        checkboxes.forEach((box) => {
            const name = box.getAttribute("name");
            payload[name] = box.checked;
        });

        return payload;
    }
}
